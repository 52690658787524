import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import Dropdown from "../dropdown";
import RemoveButton from "../removeButton";
import SelectInsurance from "../selectInsurance";
import DropdownActions from "../dropdownActions";
import styles from "./insurances.module.css";
import { useValidationError } from "../../../helpers/hooks";

const defaultInsurance = {
  plan_id: "",
  company: "",
  network: "",
  plan: "",
  other_company: "",
  other_network: "",
  other_plan: ""
};

const Insurances = ({ insurances, insuranceGroups, submitChanges, selectedInsurances, validation, showRequiredText }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }
  const { t } = useTranslation();

  const refButton = useRef();
  const isRequired = Object.keys(validation).length ? validation.required : true;
  const isRequiredText = validation.required && !selectedInsurances?.length && showRequiredText;
  const [mainErrors, setMainErrors] = useState({});

  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  useEffect(() => {
    if (!selectedList.length) {
      setSelectedList(insurances);
    } else {
      const newList = selectedList.map((item, index) => {
        return insurances[index] ? insurances[index] : item;
      });
      setSelectedList(newList);
    }
  }, [insurances.length]);

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const onSubmit = list => {
    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = list
        .map(item => {
          const { isNew, network, company, ...rest } = item;
          return {
            ...rest,
            company_id: item?.company?.insurance_company_id || item.company_id || "",
            network_id: item?.network?.insurance_networks_id || item?.network_id || ""
          };
        })
        .filter(item => Object.values(item).some(listItem => listItem));

      submitChanges({ insurances: updatedList }, "insurances", "save_insurance");
    }
  };

  const handleSubmit = (data, isNew, index) => {
    if (isNew) {
      const newList = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data, isNew };
        }

        return { ...item, isNew };
      });

      setSelectedList(newList);
      onSubmit(newList);
    } else {
      const newList = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data };
        }

        return { ...item };
      });

      setSelectedList(newList);
      onSubmit(newList);
    }
  };

  const handleAddEmpty = () => {
    if (refButton?.current && selectedList.filter(item => item.isNew).length) {
      refButton.current.click();
    }

    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = selectedList.filter(item => Object.values(item).some(listItem => listItem));

      if (selectedList.length === updatedList.length) {
        setSelectedList([...selectedList, { ...defaultInsurance, isNew: true }]);
      }
    }
  };

  const handleRemove = index => {
    const newSelectedList = selectedList.filter((item, currentIndex) => {
      return String(index) !== String(currentIndex);
    });

    onSubmit(newSelectedList);
    setSelectedList(newSelectedList);
  };

  const selectedLength = insurances.length;
  const title = `${validation?.custom_name || `Insurance${selectedLength > 1 ? "s" : ""}`}${selectedLength ? ` (${selectedLength})` : ""}`;

  return (
    <div>
      <Dropdown isRequired={isRequired} title={title} isRequiredText={isRequiredText} handleMouseMove={handleMouseMove}>
        <div className={styles.content}>
          <div className={styles.insuranceContent}>
            {selectedList.map((item, index) => {
              return (
                <InsuranceItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${index}-insurance-${item.company}-${item.other_company}-${item.other_plan}`}
                  handleSubmit={handleSubmit}
                  handleRemove={handleRemove}
                  handleRemoveEmpty={handleRemove}
                  selected={item}
                  insurances={insurances}
                  index={index}
                  insuranceGroups={insuranceGroups}
                  isRequired={isRequired}
                  refButton={refButton}
                  setIsEdited={setIsEdited}
                  isEdited={isEdited}
                  setMainErrors={setMainErrors}
                  mainErrors={mainErrors}
                />
              );
            })}
          </div>
        </div>
        <DropdownActions buttonHandle={handleAddEmpty} buttonText={t("dashboard_single_listing_addinsurance")} />
      </Dropdown>
    </div>
  );
};

const InsuranceItem = ({
  selected,
  handleSubmit: onSubmit,
  index,
  handleRemove,
  isRequired,
  insuranceGroups,
  handleRemoveEmpty,
  refButton,
  isEdited,
  setIsEdited,
  setMainErrors,
  mainErrors
}) => {
  const { t } = useTranslation();
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const { isNew } = selected;
  const containerRef = useRef();
  const [isTouched, setIsTouched] = useState(false);

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const validate = values => {
    const { plan_id, other_company, other_plan, company } = values;
    const errors = {};

    const requiredText = t("dashboard_common_required");

    if (!company && !other_company) {
      errors.company = requiredText;
    }

    if (!plan_id && !other_plan) {
      errors.plan_id = requiredText;
    }

    if (!other_company && !company) {
      errors.other_company = requiredText;
    }

    if (!other_plan && !plan_id) {
      errors.other_plan = requiredText;
    }

    const mainErrorsValue = mainErrors;
    mainErrorsValue[index] = Object.keys(errors).length ? errors : null;
    setMainErrors(mainErrorsValue);

    return errors;
  };

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: { ...selected, isEdited: false },
    onSubmit: values => {
      const normalizedData = {
        plan_id: values?.plan_id?.insurance_plan_id || "",
        company_id: values?.company?.insurance_company_id || values?.company_id || "",
        company: values?.company,
        network: values?.network,
        network_id: values?.network?.insurance_networks_id || values?.network_id || "",
        other_company: values.other_company,
        other_network: values.other_network,
        other_plan: values.other_plan,
        prkey: values.prkey
      };

      return onSubmit(normalizedData, isNew, index);
    },
    validate
  });

  const { values, errors, setFieldValue, handleSubmit } = formik;

  const handleSelectChange = (event, action, isEditedValue) => {
    if (!values.isEdited && isEditedValue) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }

    if (event.value === "" && action.name === "company") {
      setFieldValue("network", "");
      setFieldValue("plan_id", "");
      setFieldValue("company", "");
      return;
    }

    if (event.value === "" && action.name === "network") {
      setFieldValue("network", "");
      setFieldValue("plan_id", "");
      return;
    }

    if (event.value === "" && action.name === "plan_id") {
      setFieldValue("plan_id", "");
      return;
    }

    if (action.name === "company") {
      setFieldValue("network", "");
      setFieldValue("plan_id", "");
    }

    setFieldValue(action.name, event);
  };

  const handleChange = (name, value) => {
    if (!isEdited) {
      setIsEdited(true);
    }

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    setFieldValue(name, value);
  };

  const onHandleSubmit = () => {
    formik.setFieldTouched("company");
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (
        (values.other_company || values.other_plan || values.company || values.network || values.other_network || values.plan_id) &&
        values.isEdited &&
        isEdited &&
        !Object.values(errors).some(item => item)
      ) {
        handleSubmit();
        setFieldValue("isEdited", false);
      }
      setIsEdited(false);
    }
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (
            (values.other_company || values.other_plan || values.company || values.network || values.other_network || values.plan_id) &&
            values.isEdited &&
            !Object.values(errors).some(item => item)
          ) {
            handleSubmit();
            setFieldValue("isEdited", false);
          }
          setIsEdited(false);
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [
    isEdited,
    values.isEdited,
    values.other_company,
    values.other_plan,
    values.company,
    values.network,
    values.other_network,
    values.plan_id
  ]);

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const normalizeNetwork = () => {
    return insuranceGroups.networks.filter(item => item.company_id === values?.company?.insurance_company_id);
  };

  const normalizePlan = () => {
    return insuranceGroups.plans.filter(item => item.company_id === values?.company?.insurance_company_id);
  };

  const companiesOptions = [...insuranceGroups.companies, { value: "", name: "Custom" }];
  const networksOptions = [...normalizeNetwork(), { value: "", name: "Custom" }];
  const plansOptions = [...normalizePlan(), { value: "", name: "Custom" }];

  const onRemove = () => {
    if (selected.isNew) {
      handleRemoveEmpty(index);
      const mainErrorsValue = mainErrors;

      if (mainErrorsValue[index]) {
        mainErrorsValue[index] = null;
        setMainErrors(mainErrorsValue);
      }
    } else {
      handleRemove(index, index, true);
    }
  };

  useValidationError(Object.values(mainErrors).some(item => Boolean(item)) ? { insurances: "Error" } : "isRemove", "insurances");

  return (
    <div
      ref={containerRef}
      onTouchStart={handleTouchStart}
      className={styles.insurance}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      <span className={styles.insuranceName}>{t("dashboard_single_listing_popup_insurance_company")}</span>
      <SelectInsurance
        error={errors.company}
        getOptionValue={option => option.insurance_company_id}
        getOptionLabel={option => option.name}
        isInvalid={errors.company}
        name="company"
        handleSelectChange={handleSelectChange}
        options={companiesOptions}
        value={values.company || values.other_company}
        required={isRequired}
        className={styles.select}
        classNameWrap={styles.fullWidth}
        placeholder={t("selectinsurancecompany")}
        otherName="other_company"
        otherNameValue={values.other_company}
        handleChangeInput={handleChange}
        otherError={errors.other_company}
        otherIsInvalid={errors.other_company}
      />
      <RemoveButton className={styles.removeButton} onClick={onRemove} />

      <div className={styles.flex}>
        <div className={styles.inputContent}>
          <span className={styles.label}>Network</span>
          <SelectInsurance
            error={errors.network}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.insurance_networks_id}
            isInvalid={errors.network}
            name="network"
            handleSelectChange={handleSelectChange}
            options={networksOptions}
            value={values.network}
            noOptionsMessage={() => "Sorry, there are no matching Insurance Company"}
            required={isRequired}
            classNameWrap={styles.halfWidth}
            placeholder={t("selectinsurancenetwork")}
            otherName="other_network"
            otherNameValue={values.other_network}
            handleChangeInput={handleChange}
            otherError={errors.other_network}
            otherIsInvalid={errors.other_network}
          />
        </div>

        <div className={styles.inputContent}>
          <span className={styles.label}>Plan</span>
          <SelectInsurance
            error={errors.plan_id}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.insurance_plan_id}
            isInvalid={errors.plan_id}
            name="plan_id"
            handleSelectChange={handleSelectChange}
            options={plansOptions}
            value={values.plan_id}
            className={styles.select}
            noOptionsMessage={() => "Sorry, there are no matching Insurance Network"}
            required={isRequired}
            classNameWrap={styles.halfWidth}
            placeholder={t("selectinsuranceplan")}
            otherName="other_plan"
            otherNameValue={values.other_plan}
            handleChangeInput={handleChange}
            otherError={errors.other_plan}
            otherIsInvalid={errors.other_plan}
          />
        </div>
      </div>

      <button ref={refButton} type="button" className={styles.hidden} onClick={onHandleSubmit} />
    </div>
  );
};

export default Insurances;
