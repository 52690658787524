import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import Dropdown from "../dropdown";
import DropdownActions from "../dropdownActions";
import Checkbox from "../../common/checkbox";
import Input from "../input";
import Select from "../select";
import { getCountryNameFromCode, getStateNameFromCode, renderSelectCountries, renderSelectStates } from "../../../helpers/select";
import { customStyles as selectStyles } from "../../../constants/select";
import styles from "./jobInformation.module.css";
import { useValidationError } from "../../../helpers/hooks";

const workType = [
  {
    name: "In-Person",
    id: "1"
  },
  {
    name: "Hybrid",
    id: "2"
  },
  {
    name: "Remote",
    id: "3"
  }
];

const jobType = [
  {
    name: "Full Time",
    id: "1"
  },
  {
    name: "Part Time",
    id: "2"
  },
  {
    name: "Contract",
    id: "3"
  },
  {
    name: "Temporary",
    id: "4"
  },
  {
    name: "Volunteer",
    id: "5"
  },
  {
    name: "Internship",
    id: "6"
  }
];

const JobInformation = ({ countries, states, jobs, submitChanges, validation, showRequiredText, jobsLists }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }

  const isEmptyJob = !jobsLists?.length;
  const isRequiredText = validation.required && isEmptyJob && showRequiredText;
  const [isShowEmpty, setIsShowEmpty] = useState(false);

  const handleShowEmpty = () => {
    if (!isShowEmpty) {
      setIsShowEmpty(true);
    }
  };

  const title = `${validation?.custom_name || "Job Information"}`;

  return (
    <Dropdown title={title} isRequiredText={isRequiredText}>
      <div>
        <JobInformationItem countries={countries} states={states} jobs={jobs} submitChanges={submitChanges} />
      </div>
      <DropdownActions buttonHandle={handleShowEmpty} />
    </Dropdown>
  );
};

const JobInformationItem = ({ countries, states, jobs, submitChanges }) => {
  const { t } = useTranslation();
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const containerRef = useRef();
  const [isTouched, setIsTouched] = useState(false);

  const formik = useFormik({
    initialValues: {
      jobType: jobs.type_id ? JSON.parse(jobs.type_id) : [],
      workType: jobs.workplace_type_id ? JSON.parse(jobs.workplace_type_id) : [],
      state: { title: jobs.location_state || "" },
      country: { title: jobs.location_country || "" },
      city: jobs.location_city,
      compensation: jobs.compensation,
      prkey: jobs.prkey,
      isEdited: false
    },
    onSubmit: values => {
      const queryParams = {
        type_id: JSON.stringify(values.jobType),
        workplace_type_id: JSON.stringify(values.workType),
        location_country: values.country.title,
        location_city: values.city,
        location_state: values.state.title,
        compensation: values.compensation,
        prkey: values.prkey
      };
      return submitChanges({ jobs: [queryParams] }, "jobs", "save_job_info");
    }
  });

  const { values, setFieldValue, touched, errors, handleSubmit, setFieldTouched } = formik;

  const handleBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  const handleChangeJobType = value => {
    const toggleValues = values.jobType.includes(value) ? values.jobType.filter(item => item !== value) : [...values.jobType, value];

    setFieldValue("jobType", toggleValues);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }
  };

  const handleChangeWorkType = value => {
    const toggleValues = values.workType.includes(value) ? values.workType.filter(item => item !== value) : [...values.workType, value];

    setFieldValue("workType", toggleValues);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }
  };

  const handleChange = e => {
    setFieldValue(e.target.name, e.target.value);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }
  };

  const handleSelectChange = (newValue, actionMeta) => {
    setFieldValue(actionMeta.name, newValue);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (
        (values.jobType || values.workType || values.country || values.city || values.state || values.compensation) &&
        values.isEdited &&
        !Object.values(errors).some(item => item)
      ) {
        handleSubmit();
        setFieldValue("isEdited", false);
      }
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const isShowLocation = values.workType.includes("1") || values.workType.includes("2");

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (
            (values.jobType || values.workType || values.country || values.city || values.state || values.compensation) &&
            values.isEdited &&
            !Object.values(errors).some(item => item)
          ) {
            handleSubmit();
            setFieldValue("isEdited", false);
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [values.isEdited]);

  const countryOptions = renderSelectCountries(countries, false).map((item, index) => ({ id: index, title: item }));
  const stateOptions = renderSelectStates(values.country.title, states, false).map((item, index) => ({ id: index, title: item }));

  useValidationError(Object.values(errors).some(item => Boolean(item)) ? { jobInformation: "Error" } : "isRemove", "jobInformation");

  return (
    <div
      className={styles.item}
      ref={containerRef}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
    >
      <span className={styles.label}>Job Type</span>
      <div className={styles.flex}>
        {jobType.map(item => {
          return (
            <Checkbox
              key={item.name}
              isNewStyles
              checked={values.jobType.includes(item.id)}
              value={item.name}
              className={styles.checkbox}
              id={item.name}
              name={item.name}
              onChange={() => handleChangeJobType(item.id)}
            >
              <span>{item.name}</span>
            </Checkbox>
          );
        })}
      </div>
      <span className={styles.label}>Workplace Type</span>
      <div className={styles.flex}>
        {workType.map(item => {
          return (
            <Checkbox
              key={item.name}
              isNewStyles
              checked={values.workType.includes(item.id)}
              value={item.name}
              className={styles.checkbox}
              id={item.name}
              name={item.name}
              onChange={() => handleChangeWorkType(item.id)}
            >
              <span>{item.name}</span>
            </Checkbox>
          );
        })}
      </div>

      {isShowLocation && (
        <>
          <span className={styles.label}>Workplace Location</span>

          <div className={styles.country}>
            <span className={styles.label}>{t("inputcountry")}</span>
            <Select
              error={errors.country}
              isInvalid={errors.country && touched.country}
              getOptionLabel={option => getCountryNameFromCode(countries, option.title)}
              getOptionValue={option => option.title}
              inputId="country"
              name="country"
              options={countryOptions}
              onBlur={() => handleBlur(formik, "country")}
              handleChange={handleSelectChange}
              styles={selectStyles}
              value={[values.country]}
              classNameWrap={styles.selectCountry}
              placeholder={t("inputcountry")}
            />
          </div>

          <div className={styles.location}>
            <div className={styles.locationItem}>
              <span className={styles.label}>{t("inputstate")}</span>
              <Select
                error={errors.state}
                getOptionLabel={option => getStateNameFromCode(states, option.title)}
                getOptionValue={option => option.title}
                id="state"
                inputId="state"
                isInvalid={errors.state && touched?.state}
                name="state"
                noOptionsMessage={() => "Please select your country first"}
                onBlur={() => handleBlur(formik, "state")}
                handleChange={handleSelectChange}
                options={stateOptions}
                value={[values.state]}
                classNameWrap={styles.select}
                placeholder={t("inputstate")}
              />
            </div>

            <div className={styles.locationItem}>
              <span className={styles.label}>{t("inputcity")}</span>
              <Input
                error={errors.city}
                id="city"
                isInvalid={errors.city && touched.city}
                name="city"
                onBlur={e => handleBlur(formik, e.target.name)}
                onChange={handleChange}
                value={values.city}
                className={styles.input}
                placeholder={t("inputcity")}
              />
            </div>
          </div>
        </>
      )}

      <div>
        <span className={styles.label}>Compensation</span>
        <Input
          error={errors.compensation}
          id="compensation"
          isInvalid={errors.compensation && touched.compensation}
          name="compensation"
          onBlur={e => handleBlur(formik, e.target.name)}
          onChange={handleChange}
          value={values.compensation}
          className={styles.compensationInput}
          placeholder="$2000 - $3000 a month"
        />
      </div>
    </div>
  );
};

export default JobInformation;
