import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import Dropdown from "../dropdown";
import RemoveButton from "../removeButton";
import Input from "../input";
import DropdownActions from "../dropdownActions";
import CalendarWithInputMask from "../calendarWithInputMask";
import styles from "./educations.module.css";
import { useValidationError } from "../../../helpers/hooks";

const Educations = ({ selectedEducations, submitChanges, validation, showRequiredText }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }

  const refButton = useRef();

  const isRequired = Object.keys(validation).length ? validation.required : true;
  const isNotRequired = !Object.keys(validation).length;
  const isRequiredText = validation.required && !selectedEducations?.length && showRequiredText;
  const [mainErrors, setMainErrors] = useState({});
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);

  const [isEdited, setIsEdited] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  useEffect(() => {
    if (!selectedList.length) {
      setSelectedList(selectedEducations);
    } else {
      const newList = selectedList.map((item, index) => {
        return selectedEducations[index] ? selectedEducations[index] : item;
      });
      setSelectedList(newList);
    }
  }, [selectedEducations.length]);

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const onSubmit = list => {
    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = list
        .map(item => {
          const { isNew, ...rest } = item;
          return { ...rest };
        })
        .filter(item => Object.values(item).some(listItem => listItem));

      submitChanges({ education: updatedList }, "education", "save_education");
    }
  };

  const handleSubmit = (data, isNew, index) => {
    if (isNew) {
      const newSelectedList = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data, isNew };
        }

        return { ...item, isNew };
      });

      setSelectedList(newSelectedList);
      onSubmit(newSelectedList);
    } else {
      const newSelectedList = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data };
        }

        return { ...item };
      });

      setSelectedList(newSelectedList);
      onSubmit(newSelectedList);
    }
  };

  const handleAddEmpty = () => {
    if (refButton?.current && selectedList.filter(item => item.isNew).length) {
      refButton.current.click();
    }

    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = selectedList.filter(item => Object.values(item).some(listItem => listItem));

      if (selectedList.length === updatedList.length) {
        setSelectedList([...selectedList, { edu_name: "", edu_gradyear: "", edu_major: "", edu_degree: "", isNew: true }]);
      }
    }
  };

  const handleRemove = index => {
    const newSelectedList = selectedList.filter((item, currentIndex) => {
      return String(index) !== String(currentIndex);
    });

    setSelectedList(newSelectedList);
    onSubmit(newSelectedList);
  };

  const selectedLength = selectedEducations.length;
  const title = `${validation?.custom_name || `Eduction${selectedLength > 1 ? "s" : ""}`}${selectedLength ? ` (${selectedLength})` : ""}`;

  return (
    <Dropdown isRequired={isRequired} title={title} isRequiredText={isRequiredText} handleMouseMove={handleMouseMove}>
      <div>
        <div>
          {selectedList.map((item, index) => {
            return (
              <EducationsItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.edu_major}-educations-${item.edu_degree}-${item.edu_name}-index-${index}`}
                handleSubmit={handleSubmit}
                handleRemove={handleRemove}
                handleRemoveEmpty={handleRemove}
                selected={item}
                index={index}
                isNotRequired={isNotRequired}
                isRequired={isRequired}
                refButton={refButton}
                setIsEdited={setIsEdited}
                isEdited={isEdited}
                setMainErrors={setMainErrors}
                mainErrors={mainErrors}
              />
            );
          })}
        </div>
      </div>
      <DropdownActions buttonHandle={handleAddEmpty} buttonText="Add Education" />
    </Dropdown>
  );
};

const EducationsItem = ({
  selected,
  handleSubmit: onSubmit,
  index,
  handleRemove,
  handleRemoveEmpty,
  isNotRequired,
  isRequired,
  refButton,
  isEdited,
  setIsEdited,
  setMainErrors,
  mainErrors
}) => {
  const { isNew } = selected;
  const { t } = useTranslation();
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const containerRef = useRef();

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const validate = values => {
    const { edu_degree, edu_gradyear, edu_name, edu_major } = values;
    const errors = {};

    const requiredText = t("dashboard_common_required");

    if (!edu_degree) {
      errors.edu_degree = requiredText;
    }

    if (!edu_gradyear) {
      errors.edu_gradyear = requiredText;
    } else if (edu_gradyear === "Invalid date") {
      errors.edu_gradyear = "Invalid date format";
    }

    if (!edu_name) {
      errors.edu_name = requiredText;
    }

    if (!edu_major) {
      errors.edu_major = requiredText;
    }

    const mainErrorsValue = mainErrors;
    mainErrorsValue[index] = Object.keys(errors).length ? errors : null;
    setMainErrors(mainErrorsValue);

    return errors;
  };

  const formik = useFormik({
    initialValues: { ...selected, isEdited: false },
    onSubmit: values => {
      const normalizedData = {
        edu_degree: values.edu_degree,
        edu_major: values.edu_major,
        edu_name: values.edu_name,
        edu_gradyear: formik.values.edu_gradyear,
        prkey: values.prkey
      };

      return onSubmit(normalizedData, isNew, index);
    },
    validate
  });

  const { values, setFieldValue, setFieldError, setFieldTouched, handleSubmit, errors } = formik;

  const handleChange = e => {
    setFieldValue(e.target.name, e.target.value);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  const onRemove = () => {
    if (selected.isNew) {
      handleRemoveEmpty(index);
      const mainErrorsValue = mainErrors;

      if (mainErrorsValue[index]) {
        mainErrorsValue[index] = null;
        setMainErrors(mainErrorsValue);
      }
    } else {
      handleRemove(index, index, true);
    }
  };

  const onHandleSubmit = () => {
    setFieldTouched("edu_degree");
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);
      if (
        (values.edu_degree || values.edu_major || values.edu_name || values.edu_gradyear) &&
        values.isEdited &&
        !Object.values(errors).some(item => item)
      ) {
        handleSubmit();
        setFieldValue("isEdited", false);
      }
      setIsEdited(false);
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const handleChangeDate = date => {
    // eslint-disable-next-line
    console.log("handleChangeDate  educations", date);

    formik.setFieldValue("edu_gradyear", date);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }

    setFieldError("edu_gradyear", "");
  };

  const handleChangeInput = value => {
    // eslint-disable-next-line
    console.log("handleChangeInput educations", value);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }
    if (!isEdited) {
      setIsEdited(true);
    }

    setFieldValue("edu_gradyear", value);
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (
            (values.edu_degree || values.edu_major || values.edu_name || values.edu_gradyear) &&
            values.isEdited &&
            !Object.values(errors).some(item => item)
          ) {
            handleSubmit();
          }
          setIsEdited(false);
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited, values.isEdited, values.edu_degree, values.edu_major, values.edu_name, values.edu_gradyear]);

  useValidationError(Object.values(mainErrors).some(item => Boolean(item)) ? { educations: "Error" } : "isRemove", "educations");

  return (
    <div
      className={styles.education}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      ref={containerRef}
      onTouchStart={handleTouchStart}
    >
      <div className={styles.educationName}>
        <Input
          classNameWrap={styles.position}
          placeholder={t("dashboard_listings_edit_education_degree_placeholder")}
          error={errors.edu_degree}
          id="edu_degree"
          isInvalid={errors.edu_degree}
          name="edu_degree"
          onBlur={() => handleBlur("edu_degree")}
          onChange={handleChange}
          required={isNotRequired}
          value={values.edu_degree}
          className={styles.input}
        />
        <RemoveButton className={styles.removeButton} onClick={onRemove} />
      </div>

      <div className={styles.flex}>
        <Input
          classNameWrap={styles.major}
          error={errors.edu_major}
          id="edu_major"
          isInvalid={errors.edu_major}
          name="edu_major"
          onBlur={() => handleBlur("edu_major")}
          onChange={handleChange}
          required={isNotRequired}
          value={values.edu_major}
          placeholder={t("dashboard_listings_edit_education_major_placeholder")}
          className={styles.input}
        />

        <Input
          classNameWrap={styles.inputName}
          error={errors.edu_name}
          id="edu_name"
          isInvalid={errors.edu_name}
          name="edu_name"
          onBlur={() => handleBlur("edu_name")}
          onChange={handleChange}
          required={isRequired}
          value={values.edu_name}
          placeholder={t("dashboard_listings_edit_education_university_placeholder")}
          className={styles.input}
        />

        <CalendarWithInputMask
          error={errors.edu_gradyear}
          isInvalid={errors.edu_gradyear}
          value={values.edu_gradyear ? values.edu_gradyear : null}
          onChange={date => handleChangeDate(date)}
          handleChangeInput={handleChangeInput}
          required={isNotRequired}
          placeholder={t("dashboard_listings_edit_education_year_placeholder")}
          handleSubmit={handleSubmit}
          defaultMaxDate={new Date()}
          mask="9999"
          isYear
        />

        <button ref={refButton} type="button" className={styles.hidden} onClick={onHandleSubmit} />
      </div>
    </div>
  );
};

export default Educations;
