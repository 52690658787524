import React, { useState, useEffect } from "react";

// todo remove after release
// import Select from "react-select";
// import { customStylesMultiMobile as selectStyle } from "../../../../constants/select";
import Checkbox from "../../../common/checkbox";
import styles from "./filterMessage.module.css";

// activeListings,
const FilterMessage = ({ onClose, handleFilter, setFilter, filter, children }) => {
  const [activeListingsOptions, setActiveListingsOptions] = useState([]);
  const [isUnread, setIsUnread] = useState(false);

  /*
  const normalizeOptions = activeListings?.map(({ listing_id: value, title: label }) => ({ value, label }));
  const onSelectListings = options => {
    if (options?.length) {
      setActiveListingsOptions(options)∏;
    } else {
      setActiveListingsOptions([]);
    }
  };
  */

  const onFilter = () => {
    handleFilter({ activeListings: activeListingsOptions.map(({ value }) => value), isUnread });
    setFilter({ activeListings: activeListingsOptions, isUnread });
    onClose();
  };

  useEffect(() => {
    if (filter.activeListings) {
      setActiveListingsOptions(filter.activeListings);
    }

    if (filter.isUnread) {
      setIsUnread(true);
    }
  }, [filter]);

  return (
    <div className={styles.content}>
      <h2 className={styles.subTitle}>Filter</h2>

      {/*
      <h1 className={styles.title}>{t("dashboard_proview_inbox_filtertitle")}</h1>

      <div className={styles.select}>
        <Select
          isMulti
          options={normalizeOptions}
          onChange={onSelectListings}
          placeholder="Select one or more listings"
          styles={selectStyle}
          value={activeListingsOptions}
        />
      </div>
      */}

      {/* <h1 className={styles.title}>Filter By Message Type</h1> */}

      {children}

      <Checkbox checked={isUnread} onChange={() => setIsUnread(prev => !prev)}>
        <span className={styles.checkboxValue}>Unread</span>
      </Checkbox>

      <div className={styles.footer}>
        <button type="button" className={styles.clear} onClick={onClose}>
          Clear all
        </button>
        <button type="button" className={styles.apply} onClick={onFilter}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default FilterMessage;
