import React, { useState, useCallback, useRef, useEffect } from "react";
// eslint-disable-next-line
import Cropper from "react-easy-crop";
import cn from "classnames";
import { useDropzone } from "react-dropzone";

import { ReactComponent as RotateIconNext } from "../../../media/icons/rotate-next.svg";
import { ReactComponent as RotateIconPrev } from "../../../media/icons/rotate-prev.svg";
import { enableScroll, getCoverCroppedImg } from "../../../helpers";
import { Modal } from "../../common/modal";
import { OverlayPreloader } from "../../common/preloader";
import { Button } from "../../common/button";
import styles from "./cropCoverImage.module.css";
import "./cropCoverImage.css";

const CropCoverImage = ({
  handleSubmitImage,
  listingId,
  closeModal,
  loading,
  isOpen,
  preloadFile,
  handleRemovePreloaderFile,
  deleteImage,
  defaultImage,
  coverImageId
}) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(0);
  const inputRef = useRef();

  const handleDeleteImage = () => {
    deleteImage(listingId, coverImageId).then(() => {
      handleClose();
    });
  };

  const handleRotate = () => {
    if (rotation + 90 === 360) {
      setRotation(360);
    } else {
      const rotationValue = Math.round(rotation / 90) * 90;
      setRotation((rotationValue + 90) % 360);
    }
  };

  const handleRotateBack = () => {
    const rotationValue = Math.round(rotation / 90) * 90;

    setRotation((rotationValue - 90) % 360);
  };

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImageSrc(reader.result);
      };
    } else {
      console.error("Invalid file object Cover Image");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/webp": [],
      "image/gif": [],
      "image/png": []
    },
    maxSize: 5000000
  });

  const onCropComplete = useCallback((croppedArea, croppedAreaPixelsParam) => {
    setCroppedAreaPixels(croppedAreaPixelsParam);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImg = await getCoverCroppedImg(imageSrc, croppedAreaPixels, rotation);
      handleUploadImage(croppedImg);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels]);

  const handleUploadImage = croppedImage => {
    if (croppedImage) {
      const formData = new FormData();
      formData.append("image_body", croppedImage);
      formData.append("listing_id", listingId);
      formData.append("command", "cover_image");

      handleSubmitImage(formData, "photo").then(res => {
        if (res) {
          handleClose();
        }
      });
    }
  };

  const handleReset = () => {
    setImageSrc(null);

    inputRef.current.click();
  };

  const handleZoom = event => {
    setZoom(event.target.value);
  };

  const handleRotateSlide = event => {
    setRotation(event.target.value);
  };

  const handleZoomIn = () => {
    setZoom(prevZoom => {
      return Math.min(Number(prevZoom) + 0.2, 8);
    });
  };

  useEffect(() => {
    if (isOpen && !defaultImage) {
      setTimeout(() => {
        inputRef.current.click();
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (preloadFile) {
      setImageSrc(preloadFile);
    }

    return () => {
      enableScroll();
    };
  }, []);

  const handleClose = () => {
    closeModal();
    setImageSrc(null);
    handleRemovePreloaderFile();
  };

  return (
    <Modal
      className={isOpen && !imageSrc ? styles.appHide : ""}
      closeModal={handleClose}
      clearAllModals={handleClose}
      classNameMain={styles.modal}
    >
      {loading && <OverlayPreloader overlayClassName="sectionOverlayModal" />}

      <div className={styles.App}>
        <div {...getRootProps()} className={cn(styles.dropzone, imageSrc ? styles.hide : "")} ref={inputRef}>
          <input {...getInputProps()} accept=".png, .jpg, .jpeg, .webp, .gif" />
          <p>Drag & drop an image here, or click to select one</p>
        </div>

        {imageSrc && (
          <>
            <div className={styles.cropContainer}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={2.6}
                zoomSpeed={1}
                maxZoom={8}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                rotation={rotation}
                showGrid
                classes={{
                  containerClassName: "custom-container"
                }}
              />
            </div>
            <div className={styles.flex}>
              <div className={styles.zoomContent}>
                <Button type="button" className={styles.actionButton} onClick={handleZoomIn}>
                  Zoom
                </Button>

                <input
                  type="range"
                  step="0.1"
                  min="1"
                  max="8"
                  className={styles.input}
                  value={zoom}
                  onChange={handleZoom}
                  name="range"
                  id="myRange"
                />
              </div>

              <div className={styles.rotateContent}>
                <Button type="button" className={styles.actionButton}>
                  Rotate
                </Button>

                <input
                  type="range"
                  step="90"
                  min="0"
                  max="360"
                  className={styles.input}
                  value={rotation}
                  onChange={handleRotateSlide}
                  name="rotate"
                  id="myRotate"
                />

                <div className={styles.rotateActions}>
                  <button type="button" className={styles.rotate} onClick={handleRotate}>
                    <RotateIconNext />
                  </button>
                  <button type="button" className={styles.rotate} onClick={handleRotateBack}>
                    <RotateIconPrev />
                  </button>
                  <span className={styles.rotationValue}>{rotation}</span>
                </div>
              </div>
            </div>

            <div className={styles.controls}>
              <Button type="button" className={styles.deleteImage} onClick={handleDeleteImage}>
                Delete Image
              </Button>

              <Button type="button" className={styles.changeImage} onClick={handleReset}>
                Change Image
              </Button>

              <Button type="button" className={styles.submit} onClick={showCroppedImage}>
                Apply
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default CropCoverImage;
