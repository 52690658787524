import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import styles from "./profileActions.module.css";

const ProfileActions = ({
  isBottomPosition,
  isNotFilledAllFields,
  publishListing,
  setShowRequiredText,
  listingId,
  isDraft,
  showRequiredText,
  resetDraft,
  parentId,
  validationErrors
}) => {
  const bottomPositionClassName = isBottomPosition ? styles.productActionsBottom : "";

  const renderPublishButton = () => {
    if (!isDraft || Object.values(validationErrors).length) {
      return (
        <button type="button" className={styles.primary} disabled>
          Publish Draft
        </button>
      );
    }

    if (isNotFilledAllFields) {
      return (
        <button type="button" className={styles.primary} onClick={() => setShowRequiredText(true)}>
          Publish Draft
        </button>
      );
    }

    return (
      <button type="button" className={styles.primary} onClick={() => publishListing(listingId)}>
        Publish Draft
      </button>
    );
  };

  const handleRemoveDraft = () => {
    resetDraft(listingId);
  };

  return (
    <>
      <div className={cn(styles.productActions, bottomPositionClassName)}>
        {renderPublishButton()}

        <Link to={`/public-profile/${listingId}`} type="button" className={styles.preview}>
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
            <path
              d="M11 13.5999C10.288 13.5999 9.59196 13.3888 8.99995 12.9932C8.40793 12.5977 7.94651 12.0354 7.67403 11.3776C7.40156 10.7198 7.33027 9.99595 7.46917 9.29762C7.60808 8.59929 7.95095 7.95783 8.45442 7.45436C8.95788 6.95089 9.59934 6.60802 10.2977 6.46911C10.996 6.33021 11.7198 6.4015 12.3777 6.67398C13.0355 6.94645 13.5977 7.40787 13.9933 7.99989C14.3889 8.59191 14.6 9.28793 14.6 9.99994C14.5974 10.9539 14.2172 11.8681 13.5427 12.5426C12.8681 13.2172 11.954 13.5973 11 13.5999ZM11 7.89994C10.5847 7.89994 10.1786 8.0231 9.8333 8.25386C9.48796 8.48461 9.2188 8.81258 9.05985 9.19631C8.90091 9.58003 8.85932 10.0023 8.94035 10.4096C9.02138 10.817 9.22139 11.1912 9.51508 11.4849C9.80877 11.7786 10.183 11.9786 10.5903 12.0596C10.9977 12.1406 11.4199 12.099 11.8036 11.9401C12.1874 11.7811 12.5153 11.512 12.7461 11.1666C12.9768 10.8213 13.1 10.4153 13.1 9.99994C13.1 9.44299 12.8788 8.90884 12.4849 8.51502C12.0911 8.12119 11.557 7.89994 11 7.89994ZM11 19.3099C8.29015 19.1858 5.71298 18.101 3.73 16.2499C1.55 14.3399 0.25 11.9999 0.25 9.99994C0.25 5.55994 5.88 0.689941 11 0.689941C16.12 0.689941 21.75 5.68994 21.75 9.99994C21.75 14.5199 16.22 19.3099 11 19.3099ZM11 2.18994C6.77 2.18994 1.75 6.41994 1.75 9.99994C1.75 11.5599 2.89 13.5199 4.75 15.1199C6.45719 16.716 8.66726 17.6673 11 17.8099C15.76 17.8099 20.25 13.4099 20.25 9.99994C20.25 6.76994 15.47 2.18994 11 2.18994Z"
              fill="#91B848"
            />
          </svg>
          Preview Profile
        </Link>

        {Boolean(parentId) && isDraft ? (
          <button type="button" className={styles.secondary} onClick={handleRemoveDraft}>
            Delete Draft
          </button>
        ) : null}
      </div>
      {isNotFilledAllFields && showRequiredText && <p className={styles.validationError}>Please add required fields before submitting</p>}
    </>
  );
};

export default ProfileActions;
